import { CHeader, CHeaderNav, CToggler } from '@coreui/react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import React from 'react';
import SearchAgreements from '../components/Searches/SearchAgreements';
import TheHeaderDropdown from './TheHeaderDropdown';
import styles from './hamburger.module.scss';
import classNames from 'classnames';
import SearchProducts from '../components/Searches/SearchProducts';
import { ROLES } from '../roles';
import SearchTransactions from '../components/Searches/SearchTransactions';

const TheHeaderMobile = () => {
  const sidebarShow = useStoreState((state) => state.app.sidebarShow);
  const setSidebarShow = useStoreActions((state) => state.app.setSidebarShow);
  const role = useStoreState((state) => state.auth.role);

  const toggleSidebarMobile = () => {
    const val = [false, 'responsive'].includes(sidebarShow)
      ? true
      : 'responsive';
    setSidebarShow(val);
  };

  return (
    <div className="header-components">
      <CHeader
        withSubheader
        className="d-flex flex-column align-items-start flex-sm-row align-items-sm-center"
      >
        <CHeaderNav className="ml-auto px-2 justify-content-between w-100 d-flex">
          <CToggler
            inHeader
            className={classNames(`d-lg-none p-2 ${styles.hamburger}`)}
            onClick={toggleSidebarMobile}
          />
          <TheHeaderDropdown />
        </CHeaderNav>
        <CHeaderNav className="px-2 gap-2 w-100">
          <SearchProducts className={'w-100'} />
          {[ROLES.VIBE.ADMIN, ROLES.VIBE.MANAGER, ROLES.VIBE.ANALYST, ROLES.VIBE.TRADER].indexOf(role) !== -1 && (
            <SearchAgreements className={'w-100'} />
          )}
          {ROLES.PARTNER.ALL().indexOf(role) !== -1 && (
              <SearchTransactions className={'w-100'} />
          )}
        </CHeaderNav>
      </CHeader>
    </div>
  );
};

export default TheHeaderMobile;

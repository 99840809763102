import axios from '../axios';

const OpenAIService = {
  getDefaultPrompt: (type) => () => {
    return axios
      .get(`/panel/admin/openai/default-prompt/type/${type}`)
      .then((res) => res.data);
  },
  updateDefaultprompt: (type) => (data) => {
    return axios
      .put(`/panel/admin/openai/default-prompt/type/${type}`, data)
      .then((res) => res.data);
  },
  askChatGPT: () => (data) => {
    return axios
      .post(`/panel/admin/openai/ask-chat-gpt`, data)
      .then((res) => res.data);
  },
  addOpenAIExtraDescriptionToProduct: (productId) => (data) => {
    return axios
      .put(
        `/panel/admin/openai/product/${productId}/apply-extra-description`,
        data
      )
      .then((res) => res.data);
  },
  addOpenAIExtraDescriptionToBrand: (brandId) => (data) => {
    return axios
      .put(`/panel/admin/openai/brand/${brandId}/apply-extra-description`, data)
      .then((res) => res.data);
  },
};

export default OpenAIService;

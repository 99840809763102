import React, {useEffect} from 'react'
import {
    CSidebarNavItem,
} from '@coreui/react';
import {useQuery} from "react-query";
import { useLocation } from 'react-router-dom';

const SidebarMenuCounter = ({name, to, icon, badge, service}) => {
    const location = useLocation();
    const {data, isSuccess, isError, refetch} = useQuery('counter-' + name, service())

    useEffect(() => {
        refetch()
    }, [location, refetch]);

    return (isSuccess &&
        <CSidebarNavItem name={name} to={to} badge={data.count > 0 && {...badge, text: data.count}} icon={icon}/>
    ) || (
        isError &&
        <CSidebarNavItem name={name} to={to} icon={icon}/>
    )}

export default SidebarMenuCounter

import Axios from 'axios';
import stores from './stores';

const axios = Axios.create({
  responseType: 'json',
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    ...{
      'Content-Type': 'application/json',
    },
  },
});
axios.interceptors.request.use(function (config) {
  const token = stores.getState().auth.token;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (
      error.response.status === 503 &&
      error.response.data === 'Temporary unavailable' &&
      error.response.statusText === 'Service Unavailable' &&
      window.location.pathname !== '/maintenance'
    ) {
      window.location.href = '/maintenance';
    }
    return Promise.reject(error);
  }
);

export default axios;

import React, {useEffect} from 'react'
import {
    CSidebarNavDropdown,
    CBadge
} from '@coreui/react';
import {useQuery} from "react-query";
import RepurchaseService from "../../services/RepurchaseService";
import { useLocation } from 'react-router-dom';
import style from './SidebarMenuDropDownCounter.module.scss'

const SidebarMenuDropDownCounter = ({name, to, icon, badge, children}) => {
    const location = useLocation();
    const {data, isSuccess, refetch} = useQuery('counter', RepurchaseService.getRepurchaseCounter())

    useEffect(() => {
        refetch()
    }, [location, refetch]);

    return (isSuccess &&
        <CSidebarNavDropdown name={name} to={to} badge={data.count > 0 && {...badge, text: data.count}} icon={icon}>
            {data.count > 0 &&
            <div className={style.container}>
                <CBadge color="primary" className={style.badge}>{data.count}</CBadge>
            </div>
            }
            <div>
                {children}
            </div>
        </CSidebarNavDropdown>
    )}

export default SidebarMenuDropDownCounter

import React from 'react';
import classNames from 'classnames';

const Title = function ({ children, className, ...props }) {
  return (
    <div className={classNames('page-title', className)} {...props}>
      {children}
    </div>
  );
};

export default Title;

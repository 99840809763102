import React from 'react';
import styles from './ConfirmRegister.module.scss';
import {
  CButton,
  CForm,
  CFormGroup,
  CFormText,
  CInput,
  CLabel,
} from '@coreui/react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import PanelUserService from '../../services/PanelUserService';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';
import { TheFooter } from '../../containers';
import Logo from '../../components/Logo/Logo';

const ConfirmNewPassword = () => {
  const history = useHistory();
  const params = useParams();
  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
  } = useForm();
  const [confirmPartner, { isLoading: isLoadingPasswordConfirmation }] =
    useMutation(
      PanelUserService.confirmNewPassword(params.token1, params.token2),
      {
        onSuccess: () => {
          toast.success('Twoje hasło zostało zresetowane. Zaloguj się teraz.');
          history.push('/login');
        },
        onError: (e) => {
          if (
            e?.response?.data?.errors &&
            Array.isArray(e?.response?.data?.errors)
          ) {
            toast.error(e?.response?.data?.errors[0].message);

            e.response.data.errors.map(({ invalid_property, message }) => {
              return setError(invalid_property || invalid_property, {
                message,
              });
            });
          } else {
            toast.error('Ups... Coś poszło nie tak');
          }
        },
      }
    );

  const onSubmit = async (data) => {
    await confirmPartner(data);
  };

  return (
    <>
      <div className={styles.container}>
        <Loader show={isLoadingPasswordConfirmation} />
        <div className={styles.card}>
          <Logo />
          <div className={styles.wrapper}>
            <h3>Ustawianie nowego hasła</h3>
            <CForm onSubmit={handleSubmit(onSubmit)}>
              <CFormGroup>
                <CLabel htmlFor="raw_password">Wprowadź hasło</CLabel>
                <CInput
                  type="password"
                  id="raw_password"
                  name="raw_password"
                  innerRef={register}
                />
                <CFormText className="mt-3 text-right" color="danger">
                  {errors.raw_password?.message}
                </CFormText>
              </CFormGroup>
              <CFormGroup>
                <CLabel htmlFor="raw_password_repeat">
                  Wprowadź hasło ponownie
                </CLabel>
                <CInput
                  type="password"
                  id="raw_password_repeat"
                  name="raw_password_repeat"
                  innerRef={register}
                />
                <CFormText className="mt-3 text-right" color="danger">
                  {errors.raw_password_repeat?.message}
                </CFormText>
              </CFormGroup>
              <CFormGroup>
                <CButton
                  className="w-100 d-block"
                  type="submit"
                  size="xl"
                  color="primary"
                  shape="square"
                >
                  Potwierdź
                </CButton>
              </CFormGroup>
            </CForm>
          </div>
        </div>
      </div>
      <TheFooter />
    </>
  );
};

export default ConfirmNewPassword;

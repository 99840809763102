import { CCol, CContainer, CRow } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Button from '../../components/Button/Button';
import DataCard from '../../components/DataCard/DataCard';
import Loader from '../../components/Loader/Loader';
import Title from '../../components/Title/Title';
import MatrixService from '../../services/MatrixService';
import MatrixForm from './components/MatrixForm';
import PartnerCorrectionPriceBox from './components/PartnerCorrectionPriceBox';
import PartnerCorrectionStateBox from './components/PartnerCorrectionStateBox';
import PartnerCalcParamsBox from './components/PartnerCalculatorParamsBox';
import FeedImporter from './components/FeedImporter';
import RecommendedProductsBox from './components/RecommendedProductsBox';
import { toast } from 'react-toastify';

const PartnerMatrix = () => {
  const [data, setData] = useState(null);
  const [shopId, setShopId] = useState(null);
  const [shop, setShop] = useState(null);
  const [touchedFields, setTouchedFields] = useState({});

  const {
    data: brands,
    isFetching: isBrandFetching,
    refetch: refetchBrands,
  } = useQuery(`brands`, MatrixService.getPanelBrands());

  const {
    register,
    handleSubmit,
    reset,
    formState: { dirtyFields },
  } = useForm({
    defaultValues: data,
  });
  useEffect(() => {
    reset(data);
    // eslint-disable-next-line
  }, [data]);

  const { isFetching: isMatrixLoading, refetch: refetchMatrix } = useQuery(
    `${shop}-matrix`,
    MatrixService.getPartnerData(),
    {
      enabled: !!brands,
      onSuccess: (categories) => {
        if (categories && Array.isArray(categories) && categories[0]) {
          setShopId(categories[0].shop_id);
          setShop(categories[0].shop_symbol);
        }
        const parsedValues = brands.reduce((a, b) => {
          return {
            ...a,
            ...{
              [b.brand_id]: {
                name: b.brand_name,
                bb1: b.bb1,
                bb3: b.bb3,
                bb6: b.bb6,
                bb12: b.bb12,
                bb24: b.bb24,
                bb1_modified: b.bb1_modified,
                bb3_modified: b.bb3_modified,
                bb6_modified: b.bb6_modified,
                bb12_modified: b.bb12_modified,
                bb24_modified: b.bb24_modified,
                discountNew_modified: b.discountNew_modified,
                discountUsed_modified: b.discountUsed_modified,
                discountNew: String(b.discountNew),
                discountUsed: String(b.discountUsed),

                // rv1: b.rv1,
                // rv3: b.rv3,
                // rv6: b.rv6,
                // rv12: b.rv12,
                // rv24: b.rv24,
                // rv1_modified: b.rv1_modified,
                // rv3_modified: b.rv3_modified,
                // rv6_modified: b.rv6_modified,
                // rv12_modified: b.rv12_modified,
                // rv24_modified: b.rv24_modified,
                // rv_mode: b.rv_mode,
                // rv_mode_modified: b.rv_mode_modified,

                brand_id: b.brand_id,
                shop_id: b.shop_id,
                categories: categories
                  .filter((c) => c.brand_id === b.brand_id)
                  .reduce(
                    (_a, _b) => ({
                      ..._a,
                      ...{
                        [_b.id]: {
                          name: _b.category_name,
                          category_id: _b.category_id,

                          bb1: _b.bb1,
                          bb3: _b.bb3,
                          bb6: _b.bb6,
                          bb12: _b.bb12,
                          bb24: _b.bb24,
                          discountNew: _b.discountNew,
                          discountUsed: _b.discountUsed,

                          // rv1: _b.rv1,
                          // rv3: _b.rv3,
                          // rv6: _b.rv6,
                          // rv12: _b.rv12,
                          // rv24: _b.rv24,
                          brand_id: _b.brand_id,
                          shop_id: _b.shop_id,
                          // rv_mode: _b.rv_mode,
                        },
                      },
                    }),
                    {}
                  ),
              },
            },
          };
        }, {});
        setData(parsedValues);
      },
    }
  );
  const [updateMatrix, { isLoading: isUpdateLoading }] = useMutation(
    MatrixService.updatePartnerMatrix(),
    {
      onSuccess: async () => {
        await refetchBrands();
        await refetchMatrix();
        toast.success('Zmiany w macierzy zostały zapisane');
      },
      onError: () => {
        toast.error('Wystąpił błąd');
      },
    }
  );

  const getRows = (values) => {
    const touchedFieldsFiltered = { ...touchedFields };

    if (dirtyFields) {
      for (const dirtyFieldName of Object.getOwnPropertyNames(dirtyFields)) {
        if (touchedFields.hasOwnProperty(dirtyFieldName)) {
          delete touchedFieldsFiltered[dirtyFieldName];
        }
      }
    }

    return Object.entries({ ...dirtyFields, ...touchedFieldsFiltered }).reduce(
      (rows, [id, { categories: _categories, ...object }]) => {
        const {
          categories,
          brand_name,
          brand_id,
          category_id,
          shop_id,
          ...rest
        } = values[id];
        const requiredData = {
          ...(brand_id ? { brand_id } : {}),
          ...(category_id ? { category_id } : {}),
          ...(shop_id ? { shop_id } : {}),
        };
        let categoryRows;
        if (_categories) {
          categoryRows = Object.entries(_categories).map(([cId]) => {
            return {
              ...categories[cId],
              id: cId,
            };
          });
        }

        const categoriesValues = categoryRows?.map((c, index) => {
          return Object.keys(_categories[c.id]).reduce(
            (a, key) => {
              return {
                ...a,
                [key]: c[key],
              };
            },
            { ...requiredData, id: c.id }
          );
        });

        const updatedValues = Object.entries(object).reduce(
          (a, [field]) => {
            return { ...a, [field]: rest[field] };
          },
          { ...requiredData }
        );

        return [
          ...rows,
          ...(Object.keys(object).length > 0 ? [updatedValues] : []),
          ...(categoriesValues ? categoriesValues : []),
        ];
      },
      []
    );
  };

  const onSubmit = async (values) => {
    await setTouchedFields((prevState) => prevState);
    const rows = getRows(values);
    await updateMatrix({ rows });
    await setTouchedFields({});
  };

  const isLoading = !!isMatrixLoading || !!isBrandFetching || !!isUpdateLoading;
  return (
    <>
      <Breadcrumb
        path={[
          { label: 'Panel administracyjny', url: '/' },
          { label: 'Ustawienia produktów' },
        ]}
      />
      {isLoading && <Loader show />}
      <CContainer fluid>
        <main className="c-main">
          <Title>Ustawienia produktów</Title>
          <CRow>
            <CCol sm="12" xl="12">
              <form onSubmit={handleSubmit(onSubmit)}>
                <DataCard
                  title="Macierz wartości odkupów BB (Buy Back). Wszystkie wartości BB rozumiane są jako % cen cennikowych"
                  HeaderButtons={
                    <div>
                      <Button
                        type="submit"
                        disabled={isLoading}
                        id="matrix-submit"
                      >
                        Zapisz
                      </Button>
                    </div>
                  }
                >
                  <CRow>
                    <CCol sm="12" xl="12">
                      <p>
                        Po dokonaniu zmiany wciśnij klawisz “Enter” aby zapisać
                        zmianę lub przycisk “Zapisz”, aby zapisać wszystkie
                        zmiany.
                      </p>
                    </CCol>

                    <CCol sm="12" xl="12" className="mt-3">
                      {!!data && (
                        <MatrixForm
                          data={data}
                          register={register}
                          admin={false}
                          setTouchedFields={setTouchedFields}
                        />
                      )}
                    </CCol>
                  </CRow>
                </DataCard>
              </form>
            </CCol>
          </CRow>
          <Title className="mt-2 mb-0">Parametry kalkulacji</Title>
          <p className="mb-0">
            Korekta wartości odkupu dla przedziałów cenowych. Wartości rozumiane
            jako dodatkowy % dodawany lub odejmowany od wartości BB także
            wyrażonej w %
          </p>

          <CRow>
            <CCol sm="4" xl="4">
              <PartnerCorrectionStateBox />
            </CCol>
            <CCol sm="8" xl="8">
              <PartnerCorrectionPriceBox />
            </CCol>
          </CRow>
          <Title className="mt-2 mb-0">Rabaty partnerskie</Title>
          <p className="mb-4">Wszystkie dane w %</p>
          <CRow>
            <CCol>
              <PartnerCalcParamsBox shop={shop} adminMode={false} />
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <FeedImporter shop={shop} shopId={shopId} />
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <RecommendedProductsBox />
            </CCol>
          </CRow>
        </main>
      </CContainer>
    </>
  );
};

export default PartnerMatrix;

import { ROLES } from '../roles';
import RepurchaseService from "../services/RepurchaseService";
import ApplicationService from "../services/ApplicationService";

export default [
  {
    _tag: 'CSidebarNavItem',
    name: 'Dashboard',
    to: '/',
    icon: 'cil-speedometer',
    scopes: [ROLES.VIBE.ADMIN],
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Produkty',
    route: '/products',
    icon: 'cil-house',
    scopes: ROLES.ALL(),
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Produkty główne',
        to: '/products/main',
        scopes: ROLES.ALL(),
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Produkty dodatkowe',
        to: '/products/additional',
        scopes: ROLES.ALL(),
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Akcesoria',
        to: '/products/accessories',
        scopes: ROLES.ALL(),
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Usługi',
        to: '/products/services',
        scopes: ROLES.ALL(),
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Kategorie',
        to: '/categories',
        scopes: ROLES.VIBE.ALL(),
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Marki',
        to: '/brands',
        scopes: ROLES.VIBE.ALL(),
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Import',
        to: '/product-acceptance-list',
        scopes: ROLES.VIBE.ALL(),
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Ustawienia',
        to: '/matrix',
        scopes: ROLES.VIBE.ALL().concat([
          ROLES.PARTNER.PARTNER,
          ROLES.PARTNER.MANAGER,
        ]),
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Integracja AI',
        to: '/products-descriptions',
        scopes: ROLES.VIBE.ALL(),
      },
    ],
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Aktywa i usługi',
    to: '/assets&services',
    icon: 'cil-library-building',
    scopes: [
      ROLES.VIBE.ADMIN,
      ROLES.VIBE.TRADER,
      ROLES.VIBE.ANALYST,
      ROLES.VIBE.MANAGER,
    ],
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Aktywa',
        to: '/assets&services/assets',
        scopes: [
          ROLES.VIBE.ADMIN,
          ROLES.VIBE.TRADER,
          ROLES.VIBE.ANALYST,
          ROLES.VIBE.MANAGER,
        ],
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Usługi',
        to: '/assets&services/services',
        scopes: [
          ROLES.VIBE.ADMIN,
          ROLES.VIBE.TRADER,
          ROLES.VIBE.ANALYST,
          ROLES.VIBE.MANAGER,
        ],
      },
    ],
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Rezerwacje',
    to: '/reservations',
    icon: 'cil-basket',
    scopes: ROLES.ALL().filter((role) => role !== ROLES.VIBE.PRODUCT_MANAGER),
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Wnioski',
    route: '/applications',
    icon: 'cil-envelope-open',
    scopes: [
      ROLES.VIBE.ADMIN,
      ROLES.VIBE.MANAGER,
      ROLES.VIBE.TRADER,
      ROLES.VIBE.ANALYST,
    ],
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Wnioski aktywne',
        to: '/applications/active',
        scopes: [
          ROLES.VIBE.ADMIN,
          ROLES.VIBE.MANAGER,
          ROLES.VIBE.TRADER,
          ROLES.VIBE.ANALYST,
        ],
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Wnioski rozpatrzone',
        to: '/applications/examined',
        scopes: [
          ROLES.VIBE.ADMIN,
          ROLES.VIBE.MANAGER,
          ROLES.VIBE.TRADER,
          ROLES.VIBE.ANALYST,
        ],
      },
      {
        _tag: 'SidebarMenuCounter',
        name: 'Wnioski z błędem BIK',
        to: '/applications/error',
        badge: {
          color: 'danger',
        },
        service: ApplicationService.getBIKErrorCounter,
        scopes: [
          ROLES.VIBE.ADMIN,
          ROLES.VIBE.MANAGER,
          ROLES.VIBE.TRADER,
          ROLES.VIBE.ANALYST,
        ],
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Wnioski wycofane',
        to: '/applications/archived',
        scopes: [
          ROLES.VIBE.ADMIN,
          ROLES.VIBE.MANAGER,
          ROLES.VIBE.TRADER,
          ROLES.VIBE.ANALYST,
        ],
      },
    ],
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Zamówienia',
    to: '/orders',
    icon: 'cil-cart',
    scopes: ROLES.PARTNER.ALL(),
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Zamówienia',
    route: '/orders',
    icon: 'cil-envelope-open',
    scopes: [
      ROLES.VIBE.ADMIN,
      ROLES.VIBE.TRADER,
      ROLES.VIBE.ANALYST,
      ROLES.VIBE.MANAGER,
    ],
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Zamówienia aktywne',
        to: '/orders',
        scopes: [
          ROLES.VIBE.ADMIN,
          ROLES.VIBE.TRADER,
          ROLES.VIBE.ANALYST,
          ROLES.VIBE.MANAGER,
        ],
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Zamówienia wycofane',
        to: '/orders/archived',
        scopes: [
          ROLES.VIBE.ADMIN,
          ROLES.VIBE.TRADER,
          ROLES.VIBE.ANALYST,
          ROLES.VIBE.MANAGER,
        ],
      },
    ],
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Umowy',
    to: '/agreements',
    icon: 'cil-pencil',
    scopes: [
      ROLES.VIBE.ADMIN,
      ROLES.VIBE.TRADER,
      ROLES.VIBE.ANALYST,
      ROLES.VIBE.MANAGER,
    ],
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Klienci',
    to: '/customers',
    icon: 'cil-people',
    scopes: [
      ROLES.VIBE.ADMIN,
      ROLES.VIBE.TRADER,
      ROLES.VIBE.ANALYST,
      ROLES.VIBE.MANAGER,
    ],
  },
  {
    _tag: 'SidebarMenuCounter',
    name: 'Zwroty i odkupy',
    to: '/repurchase',
    icon: 'cil-cash',
    badge: {
      color: 'primary',
    },
    service: RepurchaseService.getRepurchaseCounter,
    scopes: ROLES.ALL().filter((role) => role !== ROLES.VIBE.PRODUCT_MANAGER),
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Płatności',
    to: '/payments',
    icon: 'cib-cashapp',
    scopes: [
      ROLES.VIBE.ADMIN,
      ROLES.VIBE.MANAGER,
      ROLES.VIBE.TRADER,
      ROLES.VIBE.ANALYST,
    ],
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Partnerzy',
    to: '/',
    icon: 'cil-library-building',
    scopes: ROLES.VIBE.ALL(),
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Lista Partnerów',
        to: '/partners',
        scopes: ROLES.VIBE.ALL(),
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Procedury',
        to: '/manuals',
        scopes: ROLES.VIBE.ALL(),
      },
    ],
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Dane Partnera',
    to: '/partner-data',
    icon: 'cis-people',
    scopes: ROLES.PARTNER.ALL(),
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Ustawienia',
        to: '/partner-data',
        icon: 'cil-settings',
        scopes: ROLES.PARTNER.ALL(),
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Pracownicy',
        to: '/users',
        icon: 'cis-people',
        scopes: ROLES.ALL(),
      },
    ],
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Ustawienia',
    to: '/settings',
    icon: 'cil-settings',
    scopes: ROLES.VIBE.ALL(),
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Ustawienia',
        to: '/settings',
        scopes: [ROLES.VIBE.ADMIN, ROLES.VIBE.MANAGER],
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Vouchery',
        to: '/vouchers',
        scopes: ROLES.VIBE.ALL().filter(
          (role) => role !== ROLES.VIBE.PRODUCT_MANAGER
        ),
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Pracownicy',
        to: '/users',
        scopes: [ROLES.VIBE.ADMIN, ROLES.VIBE.MANAGER],
      },
    ],
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'E-commerce',
    to: '/ecommerce',
    icon: 'cil-cart',
    scopes: [
      ROLES.VIBE.ADMIN,
      ROLES.VIBE.MANAGER,
      ROLES.VIBE.TRADER,
      ROLES.VIBE.PRODUCT_MANAGER,
    ],
  },
];

import stores from '../stores';

const LoaderService = {
  show: () => {
    stores.getActions().app.showLoader(true);
  },
  hide: () => {
    stores.getActions().app.showLoader(false);
  },
};

export default LoaderService;

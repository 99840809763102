import { action, persist } from 'easy-peasy';

const RecommendedProductsStore = persist(
  {
    category: '',
    setCategory: action((state, payload) => {
      state.category = payload;
    }),
  },
  {
    storage: localStorage,
  }
);

export default RecommendedProductsStore;

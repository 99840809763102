import React, { useEffect } from 'react';
import Title from '../../components/Title/Title';
import Button from '../../components/Button/Button';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import OpenAIService from '../../services/OpenAIService';
import Loader from '../../components/Loader/Loader';
import { toast } from 'react-toastify';
import { CFormText, CTextarea } from '@coreui/react';

const Product = () => {
  // ---------------------------------------------------------------------
  // ---------------- FORM -----------------------------------------------
  // ---------------------------------------------------------------------
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      prompt: '',
    },
  });

  // ---------------------------------------------------------------------
  // ---------------- GET DEFULT DATA PROMPT  ----------------------------
  // ---------------------------------------------------------------------
  const {
    data: dataPrompt,
    isSuccess: isSuccessDataPrompt,
    isLoading: isLoadingDataPrompt,
  } = useQuery(
    `OPENAI_PRODUCT_PROMPT`,
    OpenAIService.getDefaultPrompt('OPENAI_PRODUCT_PROMPT')
  );

  // ---------------------------------------------------------------------
  // ---------------- UPDATE DEFULT DATA PROMPT  -------------------------
  // ---------------------------------------------------------------------

  const [mutate, { isLoading: isLoadingUpdateDefaultDataPrompt }] = useMutation(
    OpenAIService.updateDefaultprompt('OPENAI_PRODUCT_PROMPT'),
    {
      onSuccess: () => {
        toast.success('Aktualizacja przebiegła pomyślnie.');
      },
      onError: (errors) => {
        errors.response.data.errors.map(
          (error) =>
            toast.error(
              'Pole opisu produktów musi zawierać słowo kluczowe {product_name}'
            )
          /*          setError('prompt', {
            type: 'manual',
            message: error.message,
          })*/
        );
      },
    }
  );

  // ---------------------------------------------------------------------
  // ---------------- SET DEFAULT DATA FORM ------------------------------
  // ---------------------------------------------------------------------
  useEffect(() => {
    if ((dataPrompt, isSuccessDataPrompt)) {
      reset({
        prompt: dataPrompt.value,
      });
    }
  }, [dataPrompt, isSuccessDataPrompt, reset]);

  // ---------------------------------------------------------------------
  // ---------------- SUBMIT HANDLER -------------------------------------
  // ---------------------------------------------------------------------
  const onSubmit = (values) => {
    mutate({
      value: values.prompt,
    });
  };
  // ---------------------------------------------------------------------
  // ---------------- STATUSES -------------------------------------------
  // ---------------------------------------------------------------------
  const isLoading = isLoadingDataPrompt || isLoadingUpdateDefaultDataPrompt;
  const isSuccess = isSuccessDataPrompt;

  // #####################################################################
  // #####################################################################
  // #####################################################################Ś

  return (
    <>
      {isLoading && <Loader show />}
      {isSuccess && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex align-items-center gap-3 mb-4">
            <Title className={'mb-0'}>Prompt dla opisów produktowych</Title>
            <Button type="submit">Zapisz Prompt</Button>
          </div>
          <div className="my-3 text-grey2">
            {`Zmienne do wykorzystania: {product_name}`}
          </div>
          <CTextarea innerRef={register} id="prompt" name="prompt" rows={10} />
          {errors?.prompt && (
            <CFormText className="mt-3" color="danger">
              {errors.prompt?.message}
            </CFormText>
          )}
        </form>
      )}
    </>
  );
};

export default Product;

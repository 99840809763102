import { CSwitch } from '@coreui/react';
import React, { forwardRef } from 'react';

const Checkbox = forwardRef((props, ref) => (
  <CSwitch shape="pill" color="success" innerRef={ref} {...props} />
));

export const CheckboxLabeled = forwardRef((props, ref) => (
  <CSwitch
    labelOn={'on'}
    labelOff={'off'}
    shape="pill"
    color="success"
    innerRef={ref}
    {...props}
  />
));

export default Checkbox;

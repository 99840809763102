import React, { useEffect } from 'react';
import styles from './Maintenance.module.scss';
import bg from './bg.png';
import Logo from '../../components/Logo/Logo';
import axios from '../../axios';
import { useHistory } from 'react-router-dom';

const Maintenance = () => {
  const history = useHistory();

  useEffect(() => {
    const checkMe = async () => {
      try {
        const { status } = await axios.get('/panel/me');
        status === 200 && history.push('/redirector');
      } catch (error) {
        const status = error?.response?.status;
        if (status !== 503) {
          history.push('/login');
        }
      }
    };
    const interval = setInterval(() => {
      checkMe();
    }, 5000);
    return () => clearInterval(interval);
  }, [history]);

  return (
    <div style={{ backgroundImage: `url(${bg})` }} className={styles.root}>
      <div className={styles.card}>
        <Logo width={300} />
        <h1>Przerwa techniczna, stroimy instrumenty i zaraz wracamy.</h1>
        <h2>
          Zapraszamy tymczasem do bufetu{' '}
          <span aria-label="emojis" role="img">
            &#128521;
          </span>{' '}
        </h2>
      </div>
    </div>
  );
};

export default Maintenance;

import { CButton } from '@coreui/react';
import React from 'react';
import styles from './Button.module.css';

const Button = ({ children, ...props }) => (
  <CButton
    className={styles.button}
    color="primary"
    variant="outline"
    shape="square"
    size="sm"
    {...props}
  >
    {children}
  </CButton>
);

export default Button;

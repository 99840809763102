import { action } from 'easy-peasy';

const MatrixStore = {
  updatedValues: {},
  setUpdatedValues: action((state, payload) => {
    state.updatedValues = { ...state.updatedValues, ...payload };
  }),
};

export default MatrixStore;

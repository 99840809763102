import { CSpinner } from '@coreui/react';
import { useStoreState } from 'easy-peasy';
import React from 'react';
import styles from './Loader.module.css';
const Loader = function ({ message, show }) {
  const loaderShow = useStoreState((state) => state.app.loaderShow);
  if (!loaderShow && !show) return null;
  return (
    <div
      className={`${styles.loaderContainer} d-flex justify-content-center align-items-center position-fixed flex-fill vh-100 vw-100 gap-3`}
    >
      <CSpinner
        color="primary"
        style={{ minWidth: '4rem', minHeight: '4rem', borderWidth: '4px' }}
      />
      {message && (
        <div className="text-primary">
          <b>{message}</b>
        </div>
      )}
    </div>
  );
};

export default Loader;

import CIcon from '@coreui/icons-react';
import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarMinimizer,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavDropdown,
  CSidebarNavItem,
  CSidebarNavTitle,
} from '@coreui/react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import React, { useMemo } from 'react';
import Logo from '../components/Logo/Logo';
import ROLES from '../roles'
import SidebarMenuCounter from "../components/SidebarMenuCounter/SidebarMenuCounter";
import SidebarMenuDropDownCounter from "../components/SidebarMenuCounter/SidebarMenuDropDownCounter";

// sidebar nav config
import navigation from './_nav';

const TheSidebar = () => {
  const show = useStoreState((state) => state.app.sidebarShow);
  const setSidebarShow = useStoreActions((state) => state.app.setSidebarShow);
  const role = useStoreState((state) => state.auth.user?.role?.name);
  const _navigation = useMemo(() => {
    return navigation.reduce((acc, route) => {
      if (route._children) {
        const _children = route._children.filter(
          (c) => !c.scopes || c.scopes.indexOf(role) !== -1
        );
        if (!route.scopes) {
          return [...acc, { ...route, _children }];
        }
        if (route.scopes.indexOf(role) !== -1) {
          return [...acc, { ...route, _children }];
        }
        return acc;
      }
      if (!route.scopes) {
        return [...acc, route];
      }
      if (route.scopes.indexOf(role) !== -1) {
        return [...acc, route];
      }
      return acc;
    }, []);
  }, [role]);

  return (
    <CSidebar
      show={show}
      unfoldable={true}
      dropdownMode={'noAction'}
      onShowChange={(val) => setSidebarShow(val)}
    >
      <CSidebarBrand className="d-md-down-none" to={ROLES.VIBE.ALL().includes(role) ? '/redirector' : '/'}>
        <Logo width={113} />
        <CIcon
          className="c-sidebar-brand-minimized"
          name="sygnet"
          height={35}
        />
      </CSidebarBrand>
      <CSidebarNav>
        <div className="aside-title">Panel administracyjny</div>
        <CCreateElement
          items={_navigation}
          components={{
            CSidebarNavDivider,
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle,
            SidebarMenuCounter,
            SidebarMenuDropDownCounter
          }}
        />
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none" />
    </CSidebar>
  );
};

export default React.memo(TheSidebar);

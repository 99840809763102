import React from 'react';
import Logo from '../../../components/Logo/Logo';
import styles from './Header.module.scss';

const Header = () => {
  return (
    <div className={styles.header}>
      <div>
        <h1 className={styles.h1}>Rejestracja nowego Partnera</h1>
        <h2 className={styles.h2}>Dane identyfikacyjne</h2>
      </div>
      <Logo />
    </div>
  );
};

export default Header;

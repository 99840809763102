import React from 'react';
import RSelect from 'react-select';

const Select = (props) => (
  <RSelect
    isClearable={props.isClearable}
    styles={{
      menuList: (provided, state) => ({
        ...provided,
        textAlign: 'left',
      }),
    }}
    {...props}
  />
);

export default Select;

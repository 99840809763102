import { action, computed, persist, thunk } from 'easy-peasy';
import AuthService from '../services/AuthService';

const AuthStore = persist(
  {
    token: null,
    user: null,
    role: computed((state) => {
      return state.user?.role?.name || null;
    }),
    setUser: action((state, { user, token }) => {
      state.token = token;
      state.user = user;
    }),
    isAuthenticated: computed((state) => {
      return !!state.user && !!state.token;
    }),

    clearUser: action((state) => {
      state.user = null;
      state.token = null;
    }),
    setToken: action((state, token) => {
      state.token = token;
    }),

    loginUser: thunk(async (actions, data) => {
      try {
        const { token } = await AuthService.Login(data);
        actions.setToken(token);
        const user = await AuthService.CheckToken(token);
        actions.setUser({ user, token });
      } catch (e) {
        throw new Error(e);
      }
    }),
  },
  {
    storage: localStorage,
  }
);

export default AuthStore;

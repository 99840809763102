import CIcon from '@coreui/icons-react';
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormGroup,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow,
} from '@coreui/react';
import { useStoreActions } from 'easy-peasy';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Logo from '../../components/Logo/Logo';
import LoaderService from '../../services/LoaderService';
import styles from './Login.module.css';

const Login = function () {
  const history = useHistory();
  const { register, handleSubmit } = useForm({
    defaultValues: {
      username: '',
      password: '',
    },
  });
  const loginUser = useStoreActions((actions) => actions.auth.loginUser);
  const [login] = useMutation(loginUser, {
    onSuccess: () => {
      history.push('/redirector');
    },
    onError: (e) => {
      if (e?.message) {
        toast.error(e.message);
      }
    },
  });

  const onSubmit = async ({ username, password }) => {
    LoaderService.show();
    await login({ username, password });
    LoaderService.hide();
  };

  return (
    <CRow
      className={`${styles.container} align-items-center flex-fill vh-100 text-center`}
    >
      <CCol className={styles.loginContainer}>
        <CCard className={styles.card}>
          <CCardHeader className={styles.header}>
            <Logo />
          </CCardHeader>
          <CCardBody className={styles.formContainer}>
            <CForm onSubmit={handleSubmit(onSubmit)}>
              <CFormGroup>
                <CInputGroup>
                  <CInputGroupPrepend>
                    <CInputGroupText>
                      <CIcon name="cil-user" />
                    </CInputGroupText>
                  </CInputGroupPrepend>
                  <CInput
                    name="username"
                    placeholder="Username"
                    innerRef={register}
                  />
                </CInputGroup>
              </CFormGroup>
              <CFormGroup>
                <CInputGroup>
                  <CInputGroupPrepend>
                    <CInputGroupText>
                      <CIcon name="cil-asterisk" />
                    </CInputGroupText>
                  </CInputGroupPrepend>
                  <CInput
                    type="password"
                    name="password"
                    placeholder="Password"
                    innerRef={register}
                  />
                </CInputGroup>
              </CFormGroup>
              <CFormGroup className={`${styles.formFooter} form-actions`}>
                <CButton type="submit" size="xl" color="primary" shape="square">
                  Zaloguj
                </CButton>
                <Link to={'/request-new-password'} className={'mt-2'}>
                  Nie pamiętam hasła
                </Link>
                <Link to={'/register'} className={'mt-4'}>
                  Zarejestruj się jako Partner Handlowy
                </Link>
              </CFormGroup>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default Login;

import React from 'react';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import ReactSelect from 'react-select';
import CategoryService from '../../services/CategoryService';

const MultiGoogleCategorySelect = ({ onChange, value, ...props }) => {
  const { data, isFetching } = useQuery(
    `google-categories`,
    CategoryService.getGoogleCategories()
  );

  const parsedValues = useMemo(() => {
    if (!data) return [];
    return data.map(({ googleId }) => ({
      value: googleId,
      label: googleId,
    }));
  }, [data]);

  return (
    <ReactSelect
      className="flex-fill"
      options={parsedValues}
      isMulti={true}
      name="brandIds"
      isLoading={isFetching}
      onChange={(parsedValues) =>
        onChange(parsedValues?.map((option) => option.value))
      }
      value={parsedValues.filter((option) => value?.includes(option.value))}
      styles={{
        menuList: (provided) => ({
          ...provided,
          textAlign: 'left',
        }),
      }}
      {...props}
    />
  );
};

export default MultiGoogleCategorySelect;

import axios from '../axios';

const PanelUserService = {
  createPartner: () => async (data) => {
    return await axios
      .post(`/init/panel/register`, data)
      .then((res) => res.data);
  },
  confirmPartner: (token1, token2) => async (data) => {
    return await axios
      .put(`/init/panel/confirm-new-partner/${token1}/${token2}`, data)
      .then((res) => res.data);
  },
  requestNewPassword: async (data) => {
    return await axios
      .put(`/init/panel/request-new-password`, data)
      .then((res) => res.data);
  },
  confirmNewPassword: (token1, token2) => async (data) => {
    return await axios
      .put(`/init/panel/reset-password/${token1}/${token2}`, data)
      .then((res) => res.data);
  },
};

export default PanelUserService;

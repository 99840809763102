import React from 'react';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { CContainer } from '@coreui/react';
import DataCard from '../../components/DataCard/DataCard';
import Product from './Product';
import Brand from './Brand';
import { useStoreState } from 'easy-peasy';
import ROLES from '../../roles';

const ProductsDescriptions = () => {
  const role = useStoreState((state) => state.auth.role);
  return (
    <>
      <Breadcrumb
        path={[
          { label: 'Panel administracyjny', url: '/' },
          { label: 'Integracja AI' },
        ]}
      />
      <CContainer fluid>
        <main className="c-main">
          <DataCard title={'Integracja ChatGPT 4.0'}>
            <div className="mt-3">
              <Product />
            </div>
            {role === ROLES.VIBE.ADMIN && (
              <div className="mt-3">
                <Brand />
              </div>
            )}
          </DataCard>
        </main>
      </CContainer>
    </>
  );
};

export default ProductsDescriptions;

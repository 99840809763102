import React from 'react';
import Logo from '../components/Logo/Logo';
import styles from './Footer.module.scss';
import { FaFacebookSquare, FaLinkedin } from 'react-icons/fa';
import cs from 'classnames';
import { Link } from 'react-router-dom';

const TheFooter = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.wrapper}>
        <div>
          <Logo width={150} />
        </div>
        <div>
          <p className={styles.title}>Kontakt z nami</p>
          <div className="d-flex">
            <ul className="mr-5">
              <li>UniVibe Sp. z o.o.</li>
              <li>Sarmacka 1, 02-972 Warszawa, Poland</li>
              <li>
                Tel. <a href={'tel:510 510 092'}>510 510 092</a>
              </li>
              <li>
                Email: <a href={'mailto:info@vibe.pl'}>info@vibe.pl</a>
              </li>
            </ul>
            <ul className="ml-5">
              <li>KRS 0000853684</li>
              <li>REGON 386795807</li>
              <li>NIP 9512505831</li>
            </ul>
          </div>
        </div>
        <div>
          <p className={styles.title}>Znajdź nas</p>
          <Link
            className={cs(`${styles.link} mr-3`)}
            to={{
              pathname:
                'https://www.facebook.com/profile.php?id=100027268766958',
            }}
            target="_blank"
          >
            <FaFacebookSquare className={styles.fbIcon} />
          </Link>
          <Link
            className={styles.link}
            to={{ pathname: 'https://www.linkedin.com/company/gear4rent' }}
            target="_blank"
          >
            <FaLinkedin className={styles.linkedInIcon} />
          </Link>
        </div>
      </div>
      <div className={styles.rules}>
        <p className={styles.copyRight}>
          &copy; {new Date().getFullYear()} by Vibe
        </p>
        <ul>
          <li>
            <Link
              to={{
                pathname:
                  'https://vibe.pl/wp-content/uploads/Regulamin-swiadczenia-uslug-UniVibe-droga-elektroniczna.pdf',
              }}
              target={'_blank'}
            >
              Regulamin świadczenia usług
            </Link>
          </li>
          <li>
            <Link
              to={{
                pathname:
                  'https://vibe.pl/wp-content/uploads/Polityka-Cookies-UniVibe.pdf',
              }}
              target={'_blank'}
            >
              Polityka Cookies
            </Link>
          </li>
          <li>
            <Link
              to={{
                pathname:
                  'https://vibe.pl/wp-content/uploads/Polityka-Prywatnosci-UniVibe.pdf',
              }}
              target={'_blank'}
            >
              Polityka Prywatności
            </Link>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default React.memo(TheFooter);

import axios from '../axios';
// import ALL_RECOMMENDED_PRODUCS from '../views/RecommendedProducts/components/ALL_RECOMMENDED_PRODUCS.json'

/*const fetchAllRecommendedProducts = () => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve(ALL_RECOMMENDED_PRODUCS);
        }, 300);
    });
}*/

const RecommendedProductsService = {
  // getActualRecommendedProducts: (mainProductId) => () => {
  //     return axios.get(`/panel/recommended-products/${mainProductId}`).then((res) => res.data).catch(() => FAKE_RESPONSE)
  // },
  // getAllRecommendedProducts: () => () => fetchAllRecommendedProducts().then(res => res.data),

  getAllMainProductCategories: (shopSymbol) => () => {
    return axios
      .get(`/panel/recommended-categories/shop/${shopSymbol}`)
      .then((res) => res.data.data);
  },

  getAllRecommendedCategoriesDropdown: (shopSymbol) => () => {
    return axios
      .get(`/panel/recommended-categories-dropdown/shop/${shopSymbol}`)
      .then((res) => res.data);
  },

  setRecommendedCategories: (mainProductCategoryId, shopSymbol) => (data) => {
    return axios
      .post(
        `/panel/recommended-categories/main-category${mainProductCategoryId}/shop/${shopSymbol}`,
        data
      )
      .then((res) => res.data);
  },
  getAllRecommendedProducts:
    (mainProductCategoryId, shopSymbol, type) => () => {
      return axios
        .get(
          `/panel/recommended-items/main-category${mainProductCategoryId}/shop/${shopSymbol}/type/${type}`
        )
        .then((res) => res.data);
    },
  setAllRecommendedProducts:
    (mainProductCategoryId, shopSymbol, type) => (data) => {
      return axios
        .post(
          `/panel/recommended-items/main-category${mainProductCategoryId}/shop/${shopSymbol}/type/${type}`,
          data
        )
        .then((res) => res.data);
    },

  getRecommendedCategoriesSwitch: (shopSymbol) => () => {
    return axios
      .get(`panel/recommended-categories-switch/shop/${shopSymbol}`)
      .then((res) => res.data);
  },

  setRecommendedCategoriesSwitch: (shopSymbol) => (data) => {
    return axios
      .post(
        `panel/admin/recommended-categories-switch/shop/${shopSymbol}`,
        data
      )
      .then((res) => res.data);
  },
};

export default RecommendedProductsService;

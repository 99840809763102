import axios from '../axios';
import { getImageSize } from 'react-image-size';

const get_original_sizes = async (images) => {
  if (!images) {
    return [];
  }
  const imagesWithSizes = [];
  for (let i in images) {
    try {
      const dimensions = await getImageSize(images[i].image_url);
      imagesWithSizes.push({
        ...images[i],
        width: dimensions.width || 300,
        height: dimensions.height,
      });
    } catch (error) {
      imagesWithSizes.push({ ...images[i], width: 300, height: null });
    }
  }
  return imagesWithSizes;
};

const get_original_size = async (image_url) => {
  if (!image_url) {
    return null;
  }
  try {
    return await getImageSize(image_url);
  } catch (error) {
    return null;
  }
};

const ProductService = {
  getData: (id) => async () => {
    return await axios.get(`/panel/product/${id}`).then(async (res) => ({
      ...res.data,
      images: await get_original_sizes(res.data.images),
    }));
  },
  getDataAdmin: (id) => async () => {
    return await axios.get(`/panel/admin/product/${id}`).then(async (res) => ({
      ...res.data,
      images: await get_original_sizes(res.data.images),
      main_image_sizes: await get_original_size(res.data.image_url),
    }));
  },
  getBrands: () => async () => {
    return await axios.get(`/panel/brands`).then((res) => res.data.data);
  },
  getCategories: () => async () => {
    return await axios.get(`/panel/categories`).then((res) => res.data.data);
  },
  update: (id) => async (data) => {
    return await axios
      .put(`/panel/product/${id}`, data)
      .then((res) => res.data);
  },
  create: () => async (data) => {
    return await axios.post(`/panel/product`, data).then((res) => res.data);
  },
  delete: (id) => async () => {
    return await axios
      .delete(`/panel/product/${id}`)
      .then(async (res) => res.data);
  },
  createAdmin: () => async (data) => {
    return await axios
      .post(`/panel/admin/product`, data)
      .then((res) => res.data);
  },
  getProductImportConfig: (shopSymbol) => async () => {
    return await axios
      .get(`/panel/product-import-config/${shopSymbol}`)
      .then((res) => res.data);
  },
  setProductImportConfig: (shopSymbol) => async (data) => {
    return await axios
      .post(`/panel/product-import-config/${shopSymbol}`, data)
      .then((res) => res.data);
  },
  runIntegration: (shopSymbol) => async (data) => {
    return await axios
      .post(`/panel/admin/product-integration/${shopSymbol}`, data)
      .then((res) => res.data);
  },
  copyService: () => async (data) => {
    return await axios
      .post(`/panel/admin/copy-service`, data)
      .then((res) => res.data);
  },
  copyProductToVibe: () => (data) => {
    return axios
      .post('/panel/admin/copy-product', data)
      .then((res) => res.data);
  },
  updatePhotos: () => async (data) => {
    return axios
      .post('/panel/product/upload-images', data)
      .then((res) => res.data);
  },
};

export default ProductService;

import React from 'react';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import ReactSelect from 'react-select';
import CategoryService from '../../services/CategoryService';

const MultiCeneoCategorySelect = ({ onChange, value, ...props }) => {
  const { data, isFetching } = useQuery(
    `ceneo-categories`,
    CategoryService.getCeneoCategories({ page: 1, records_per_page: 10000 })
  );

  const parsedValues = useMemo(() => {
    if (!data) return [];
    return data.map(({ ceneoId }) => ({
      value: ceneoId,
      label: ceneoId,
    }));
  }, [data]);

  return (
    <ReactSelect
      className="flex-fill"
      options={parsedValues}
      isMulti={true}
      name="ceneoIds"
      isLoading={isFetching}
      onChange={(parsedValues) =>
        onChange(parsedValues?.map((option) => option.value))
      }
      value={parsedValues.filter((option) => value?.includes(option.value))}
      styles={{
        menuList: (provided) => ({
          ...provided,
          textAlign: 'left',
        }),
      }}
      {...props}
    />
  );
};

export default MultiCeneoCategorySelect;

import { useStoreState } from 'easy-peasy';
import React, { useMemo } from 'react';
import { useState } from 'react';
import { useQuery } from 'react-query';
import ROLES from '../../roles';
import ShopService from '../../services/ShopService';
import Select from '../Select';

const ShopsSelect = ({ onChange, value: shopValue, all_option = false }) => {
  const role = useStoreState((state) => state.auth.role);
  const [data, setData] = useState(null);

  const isAdmin = useMemo(() => {
    return ROLES.VIBE.ALL().includes(role);
  }, [role]);

  useQuery(`shops`, ShopService.getShops(), {
    onSuccess: (data) => {
      if (all_option && data) {
        setData([
          { entity_id: -1, name: 'Wszystkie sklepy', symbol: '' },
          ...data,
        ]);
      } else {
        setData(data);
      }
    },
    enabled: isAdmin,
  });

  useQuery(`shop`, ShopService.getDataPartner(), {
    onSuccess: (data) => {
      setData([data]);
      onChange({
        id: data.id,
        name: data.name,
      });
    },
    enabled: !isAdmin,
  });

  const parsedValues = useMemo(() => {
    if (!data) {
      return [];
    } else {
      if (isAdmin) {
        return data.map(({ entity_id, name, symbol }) => ({
          value: entity_id,
          label: `${name} | ${symbol}`,
          symbol,
        }));
      } else {
        return data.map(({ id, name }) => ({
          value: id,
          label: name,
        }));
      }
    }
  }, [data, isAdmin]);

  const parsedValue = useMemo(() => {
    if (!shopValue) {
      return null;
    }
    return {
      value: shopValue.id,
      label: shopValue.name,
      symbol: shopValue.symbol,
    };
  }, [shopValue]);

  return (
    <div className="flex-fill">
      <Select
        isLoading={data === null}
        className="flex-fill"
        options={parsedValues}
        value={parsedValue}
        onChange={({ value, label, symbol }) =>
          onChange({ id: value, name: label, symbol })
        }
      />
    </div>
  );
};

export default ShopsSelect;

export const ROLES = {
  VIBE: {
    ADMIN: 'ROLE_ADMIN',
    MANAGER: 'ROLE_ADMIN_MANAGER',
    ANALYST: 'ROLE_ADMIN_ANALYST',
    TRADER: 'ROLE_ADMIN_TRADER',
    PRODUCT_MANAGER: 'ROLE_ADMIN_PRODUCT_MANAGER',
    ALL: function() {
      return Object.keys(this)
          .filter(key => key !== 'ALL')
          .map(key => this[key]);
    },
  },
  PARTNER: {
    PARTNER: 'ROLE_PARTNER',
    MANAGER: 'ROLE_MANAGER',
    TRADER: 'ROLE_TRADER',
    ALL: function() {
      return Object.keys(this)
          .filter(key => key !== 'ALL')
          .map(key => this[key]);
    },
  },
  ALL: function () {
    return this.VIBE.ALL().concat(this.PARTNER.ALL())
  }
};

export default ROLES
import axios from '../axios';
import { parseObjectToUrlQuery } from '../helpers';

const RepurchaseService = {
  getData: (type) => async (_, params) => {
    const parsedParams = params ? `?${parseObjectToUrlQuery(params)}` : '';
    return await axios
      .get(`/panel/${type}${parsedParams}`)
      .then((res) => res.data.data);
  },
  getDataTable: (type) => async (_, params) => {
    const config = params.csv ? { responseType: 'blob' } : null;
    const parsedParams = params ? `?${parseObjectToUrlQuery(params)}` : '';
    return await axios
      .get(`/panel/${type}${parsedParams}`, config)
      .then((res) => res.data);
  },
  getSingleData: (type, id) => async () => {
    return await axios.get(`/panel/${type}/${id}`).then((res) => res.data);
  },
  updateSingleData: (id) => async (data) => {
    return await axios
      .put(`/panel/admin/repurchase/${id}`, data)
      .then((res) => res.data);
  },
  updateByType: (id, type) => async (data) => {
    return await axios
      .put(`/panel/admin/${type}/${id}`, data)
      .then((res) => res.data);
  },
  getNote: (repurchase_id) => async () => {
    return await axios
      .get(`/panel/repurchase-note/${repurchase_id}`)
      .then((res) => res.data);
  },
  updateNote: (repurchase_id) => async (data) => {
    return await axios
      .put(`/panel/repurchase-note/${repurchase_id}`, data)
      .then((res) => res.data);
  },
  getRepurchaseCounter: () => async () => {
    return await axios.get('/panel/repurchases/return-protocol/counter/status/STARTED}').then((res) => res.data);
  }
};

export default RepurchaseService;

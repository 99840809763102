import { CInput } from '@coreui/react';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import Button from '../../../components/Button/Button';
import DataCard from '../../../components/DataCard/DataCard';
import DataRow from '../../../components/DataRow/DataRow';
import LoaderService from '../../../services/LoaderService';
import SettingsService from '../../../services/SettingsService';
import classes from '../Settings.module.css';
import styles from '../Matrix.module.css';

const NumberInput = ({ modified, ...props }) => {
  return (
    <div className="position-relative">
      <CInput
        {...props}
        pattern="^[+-]?\d+((.|,)\d*)?"
        className={styles.numberInput}
      />
      <div className={styles.prc}>
        <span>%</span>
      </div>
    </div>
  );
};
const CalcParamsBox = ({ shop, refetchMatrix, refetchBrands }) => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { isDirty },
  } = useForm();

  const { isFetching, refetch, data } = useQuery(
    'settings-calculator',
    () => SettingsService.getCalculatorData(shop),
    {
      onSuccess: (data) => {
        reset(data);
      },
    }
  );
  const [updateCalculatorValue] = useMutation(
    (values) => SettingsService.updateCalculatorData(values, shop),
    {
      onSuccess: async () => {
        await refetch();
        await refetchBrands();
        await refetchMatrix();

        toast.success('Zaktualizowano dane');
      },
      onError: async () => {
        LoaderService.hide();
        toast.error('Wystąpił błąd podczas zapisywania');
      },
    }
  );
  // eslint-disable-next-line
  const [updateInstallments, { isLoading: isUpdatingInstallments }] =
    useMutation(SettingsService.updateInstallments, {
      onSuccess: async () => {
        await refetch();
        toast.success('Raty aktywnych umów zostały ponownie przeliczone');
      },
      onError: async () => {
        LoaderService.hide();
        toast.error('Wystąpił błąd podczas aktualizacji wysokości rat');
      },
    });

  useEffect(() => {
    if (isFetching || isUpdatingInstallments) {
      LoaderService.show();
    } else {
      LoaderService.hide();
    }
  }, [isFetching, isUpdatingInstallments]);
  const onSubmit = (values) => {
    updateCalculatorValue(values.data);
    LoaderService.show();
  };

  const findMonthOptionIndex = (month, options) => {
    return options.findIndex((o) => parseInt(o.month) === parseInt(month));
  };

  const getOptionName = (name, month, options) => {
    let i = findMonthOptionIndex(month, options);
    return `data.month_options[${i}].${name}`;
  };

  if (!data?.data) {
    return null;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DataCard
        title="Wskaźniki marżowe i kosztowe"
        HeaderButtons={
          <div>
            <Button type="submit" disabled={!isDirty}>
              Zapisz
            </Button>
          </div>
        }
      >
        <table className="mt-4">
          <thead>
            <tr>
              <th className="p-7">Minimalne okresy najmu</th>
              <th className="p-1 text-center">1 MC</th>
              <th className="p-1 text-center">3 MC</th>
              <th className="p-1 text-center">6 MC</th>
              <th className="p-1 text-center">12 MC</th>
              <th className="p-1 text-center">24 MC</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Marża Vibe dla produktu głównego - os. fiz.</th>
              {[1, 3, 6, 12, 24].map((n) => (
                <td key={n}>
                  <NumberInput
                    required
                    placeholder="Uzupełnij"
                    name={getOptionName(
                      'profit_main_np',
                      n,
                      data.data?.month_options || []
                    )}
                    innerRef={register}
                  />
                  <CInput
                    required
                    hidden
                    placeholder="Uzupełnij"
                    name={getOptionName(
                      'month',
                      n,
                      data.data?.month_options || []
                    )}
                    innerRef={register}
                  />
                </td>
              ))}
            </tr>

            <tr>
              <th>Marża Vibe dla produktu głównego - firma, instytucja.</th>

              {[1, 3, 6, 12, 24].map((n) => (
                <td key={n}>
                  <NumberInput
                    required
                    placeholder="Uzupełnij"
                    name={getOptionName(
                      'profit_main_lp',
                      n,
                      data.data?.month_options || []
                    )}
                    innerRef={register}
                  />
                </td>
              ))}
            </tr>
            <tr>
              <th>
                Marża Vibe dla Produktów dodatkowych i akcesoriów - os. fiz.
              </th>

              {[1, 3, 6, 12, 24].map((n) => (
                <td key={n}>
                  <NumberInput
                    required
                    placeholder="Uzupełnij"
                    name={getOptionName(
                      'profit_additional_np',
                      n,
                      data.data?.month_options || []
                    )}
                    innerRef={register}
                  />
                </td>
              ))}
            </tr>
            <tr>
              <th>
                Marża Vibe dla Produktów dodatkowych i akcesoriów - firma,
                instytucja.
              </th>

              {[1, 3, 6, 12, 24].map((n) => (
                <td key={n}>
                  <NumberInput
                    required
                    placeholder="Uzupełnij"
                    name={getOptionName(
                      'profit_additional_lp',
                      n,
                      data.data?.month_options || []
                    )}
                    innerRef={register}
                  />
                </td>
              ))}
            </tr>
            <tr>
              <th>% rabatu partnerskiego na poczet ceny wykupu.</th>

              {[1, 3, 6, 12, 24].map((n) => (
                <td key={n}>
                  <NumberInput
                    required
                    placeholder="Uzupełnij"
                    name={getOptionName(
                      'partner_repurchase_discount',
                      n,
                      data.data?.month_options || []
                    )}
                    innerRef={register}
                  />
                </td>
              ))}
            </tr>
            <tr>
              <th>
                % rabatu partnerskiego zaliczany na poczet najmu produktów
                dodatkowych i akcesoriów.
              </th>

              {[1, 3, 6, 12, 24].map((n) => (
                <td key={n}>
                  <NumberInput
                    required
                    placeholder="Uzupełnij"
                    name={getOptionName(
                      'partner_additional_discount',
                      n,
                      data.data?.month_options || []
                    )}
                    innerRef={register}
                  />
                </td>
              ))}
            </tr>
            <tr>
              <th>% miesięcznej raty najmu na poczet ceny wykupu.</th>

              {[1, 3, 6, 12, 24].map((n) => (
                <td key={n}>
                  <NumberInput
                    required
                    placeholder="Uzupełnij"
                    name={getOptionName(
                      'rate_percent_repurchase',
                      n,
                      data.data?.month_options || []
                    )}
                    innerRef={register}
                  />
                </td>
              ))}
            </tr>
          </tbody>
        </table>

        <DataRow
          label="Roczny koszt finansowania"
          value={
            <>
              <div className={classes.inputContainer}>
                <NumberInput
                  required
                  placeholder="Uzupełnij"
                  name="data.annual_financing_cost"
                  innerRef={register}
                />
              </div>
            </>
          }
        />
        <DataRow
          label="Wskaźnik kaucji - osoba fizyczna"
          value={
            <div className={classes.inputContainer}>
              <NumberInput
                required
                placeholder="Uzupełnij"
                name="data.bail_factor_np"
                innerRef={register}
              />
            </div>
          }
        />
        <DataRow
          label="Wskaźnik kaucji - pozostali"
          value={
            <div className={classes.inputContainer}>
              <NumberInput
                required
                placeholder="Uzupełnij"
                name="data.bail_factor_lp"
                innerRef={register}
              />
            </div>
          }
        />
        <DataRow
          label="Rabat partnerski na produkty główne"
          value={
            <div className={classes.inputContainer}>
              <NumberInput
                required
                placeholder="Uzupełnij"
                name="data.partner_discount_main"
                innerRef={register}
              />
            </div>
          }
        />
        <DataRow
          label="Rabat partnerski na produkty dodatkowe i akcesoria"
          value={
            <div className={classes.inputContainer}>
              <NumberInput
                required
                placeholder="Uzupełnij"
                name="data.partner_discount_additional"
                innerRef={register}
              />
            </div>
          }
        />
      </DataCard>
    </form>
  );
};

export default CalcParamsBox;

import axios from '../axios';
import { downloadFile, parseObjectToUrlQuery } from '../helpers';

const ApplicationService = {
  getData: (id) => async () => {
    return await axios
      .get(`/panel/admin/application/${id}`)
      .then((res) => res.data);
  },
  getNote: (id) => async () => {
    return await axios
      .get(`/panel/admin/application-note/${id}`)
      .then((res) => res.data?.data);
  },
  updateNote: (id) => async (data) => {
    return await axios
      .put(`/panel/admin/application-note/${id}`, data)
      .then((res) => res.data);
  },
  getCompanyData: (id) => async () => {
    return await axios
      .get(`/panel/admin/application/legal-person/${id}`)
      .then((res) => res.data);
  },
  accept: (id, type) => async () => {
    return await axios
      .post(`/panel/admin/application/accept/type/${type}/id/${id}`)
      .then((res) => res.data);
  },
  reject: (id, type) => async () => {
    return await axios
      .post(`/panel/admin/application/reject/type/${type}/id/${id}`)
      .then((res) => res.data);
  },
  downloadCustomerScan: (application_id, type) => async () => {
    return await axios
      .get(
        `/panel/admin/download/customer/scan/type/${type}/application_id/${application_id}`,
        {
          responseType: 'blob',
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch(() => {
        return false;
      });
  },
  downloadFromLink: async (link, filename) => {
    return await axios
      .get(`${link}`, {
        responseType: 'blob',
      })

      .then((res) => {
        let headers = res.headers;
        let content_disposition = headers['content-disposition'] || false;
        if (!content_disposition) {
          downloadFile(res.data, filename);
        } else {
          let filename = decodeURI(
            content_disposition
              .split(';')[1]
              .split('=')[1]
              .replace("UTF-8''", '')
          );
          downloadFile(res.data, filename);
        }
      });
  },
  updateLegalPerson: (id) => async (data) => {
    return await axios
      .post(`/panel/admin/application/legal-person/params/${id}`, data)
      .then((res) => res.data);
  },
  updateNaturalPerson: (id) => async (data) => {
    return await axios
      .post(`/panel/admin/application/natural-person/params/${id}`, data)
      .then((res) => res.data);
  },
  updateIE: (id) => async (data) => {
    return await axios
      .post(
        `/panel/admin/application/individual-entrepreneur/params/${id}`,
        data
      )
      .then((res) => res.data);
  },
  updateItems: (application_id) => async (data) => {
    return await axios
      .put(`/panel/admin/application/items/replace/${application_id}`, data)
      .then((res) => res.data);
  },
  updateCustomerAddress: (application_id) => async (data) => {
    return await axios
      .put(`panel/admin/application/customer/${application_id}`, data)
      .then((res) => res.data);
  },
  checkAgainPESELRestriction: (application_id) => async () => {
    return await axios
        .put(`panel/admin/application/customer/${application_id}?check_bik_only=true`, {})
        .then((res) => res.data);
  },
  bikAgain: (application_id) => async (data) => {
    return await axios
      .post(`panel/admin/application/bik/${application_id}`, data)
      .then((res) => res.data);
  },
  archive: (id) => async () => {
    return await axios
      .put(`/panel/admin/application/archive/${id}`)
      .then((res) => res.data);
  },
  payPerLinkActivate: (id) => async () => {
    return await axios
      .post(`/panel/admin/application/pay-per-link/${id}`)
      .then((res) => res.data);
  },
  testDeposit: (id) => async (data) => {
    return await axios
      .post(`/panel/admin/test/recalculate-bail/application_id/${id}`, data)
      .then((res) => res.data);
  },
  recalculateDeposit: (id) => async (data) => {
    return await axios
      .post(`/panel/admin/recalculate-bail/application_id/${id}`, data)
      .then((res) => res.data);
  },
  addClientDocuments: (application_id, params) => (data) => {
    const parsedParams = params ? `?${parseObjectToUrlQuery(params)}` : '';
    return axios
      .post(`/panel/admin/statement/application/${application_id}${parsedParams}`, data)
      .then((res) => res.data);
  },
  take: (id) => async () => {
    return await axios
      .put(`/panel/admin/application/take/${id}`)
      .then((res) => res.data);
  },
  sendFirstPaymentLink: (id) => async () => {
    return await axios
      .post(`/panel/admin/application/send-first-payment-link/${id}`, {})
      .then((res) => res.data);
  },
  updateMinimalMonthsPeriod: (application_id) => (data) => {
    return axios
      .put(`/panel/admin/application/minimal-months/${application_id}`, data)
      .then((res) => res.data);
  },
  getBIKErrorCounter: () => async () => {
    return await axios.get('/panel/admin/application/counter/status/BIK_ERROR').then((res) => res.data);
  }
};

export default ApplicationService;

import { useStoreActions, useStoreState } from 'easy-peasy';
import React, { useEffect, useState } from 'react';
import Loader from './components/Loader/Loader';
import AuthService from './services/AuthService';

const AuthProvider = ({ children }) => {
  const [authChecked, setAuthChecked] = useState(false);
  const auth = useStoreState((state) => state.auth);
  const setUser = useStoreActions((actions) => actions.auth.setUser);
  const clearUser = useStoreActions((actions) => actions.auth.clearUser);
  const token = useStoreState((state) => state.auth.token);

  const checkUser = async () => {
    try {
      const _user = await AuthService.CheckToken(token);
      if (_user) {
        setUser({
          token: auth.token,
          user: _user,
        });
        setAuthChecked(true);
      } else {
        throw new Error('Unauthorized');
      }
    } catch (e) {
      if (e.response.status !== 503) {
        clearUser();
      }
      setAuthChecked(true);
    }
  };

  useEffect(() => {
    if (window.location.pathname === '/login') {
      setAuthChecked(true);
    } else {
      checkUser();
    }
    // eslint-disable-next-line
  }, [token]);

  if (authChecked) {
    return children;
  }
  return <Loader show />;
};

export default AuthProvider;

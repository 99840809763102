import { CBreadcrumb, CBreadcrumbItem } from '@coreui/react';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';

const Breadcrumb = function ({ path = [] }) {
  const history = useHistory();

  return (
    <CBreadcrumb className="bg-white">
      {path.map(({ label, url, back = false }, index) =>
        url && !back ? (
          <CBreadcrumbItem key={index}>
            <Link to={url}>{label}</Link>
          </CBreadcrumbItem>
        ) : back ? (
          <CBreadcrumbItem key={index} active>
            <a
              href={url}
              onClick={(e) => {
                history.goBack();
                e.preventDefault();
              }}
            >
              {label}
            </a>
          </CBreadcrumbItem>
        ) : (
          <CBreadcrumbItem key={index} active>
            {label}
          </CBreadcrumbItem>
        )
      )}
      {/* <CBreadcrumbItem>
        <Link to="/">Panel administracyjny</Link>
      </CBreadcrumbItem>
      <CBreadcrumbItem>
        <Link to="/orders">Zamówienie</Link>
      </CBreadcrumbItem>
      <CBreadcrumbItem active>Data</CBreadcrumbItem> */}
    </CBreadcrumb>
  );
};

export default Breadcrumb;
